<template>
  <section class="vista-formulario">
    <div
      class="tarjeta-form animated fadeInUp"
    >
      <h1 class="h5 user-select-none">Global Trackit System</h1>
      <h3 class="my-3 user-select-none">
        {{esRecuperarClave ? 'Nueva clave' : 'Recuperar cuenta'}}
      </h3>
      <form
        class="row justify-content-center"
        @submit.prevent="esRecuperarClave ? validarClave() : submitForm()"
      >
        <div v-if="esRecuperarClave" class="row mx-0">
          <div
            :class="['col-12',
              {'campo': validarValores.clave
                && validarValores.clave.length},
              {'valido': validarValores.clave == 'valido'},
              {'invalido': validarValores.clave == 'invalido'},
            ]"
          >
            <mdb-input
              v-model.trim="clave"
              :type="mostrarCamposClave.clave ? 'text' : 'password'"
              minlength="8"
              label="Clave"
              class="mt-2 mb-3"
              outline
            >
              <mdb-btn
                slot="append"
                group
                flat
                dark-waves
                size="md"
                :icon="mostrarCamposClave.clave ? 'eye-slash' : 'eye'"
                :title="mostrarCamposClave.clave ? 'Ocultar clave' : 'Mostrar clave'"
                @click="mostrarCamposClave.clave = !mostrarCamposClave.clave"
              />
            </mdb-input>
            <p
              v-if="validarValores.clave == 'invalido'"
              class="mensaje-invalido"
            >
              Indica la clave
            </p>
          </div>
          <div
            :class="['col-12',
              {'campo': validarValores.confirmacionClave
                && validarValores.confirmacionClave.length},
              {'valido': validarValores.confirmacionClave == 'valido'},
              {'invalido': validarValores.confirmacionClave == 'invalido'},
            ]"
          >
            <mdb-input
              v-model.trim="confirmacionClave"
              :type="mostrarCamposClave.confirmacionClave ? 'text' : 'password'"
              minlength="8"
              label="Confirmar clave"
              class="mt-2"
              outline
            >
              <mdb-btn
                slot="append"
                group
                flat
                dark-waves
                size="md"
                :icon="mostrarCamposClave.confirmacionClave ? 'eye-slash' : 'eye'"
                :title="mostrarCamposClave.confirmacionClave
                  ? 'Ocultar confirmación de la clave' : 'Mostrar confirmación de la clave'"
                @click="mostrarCamposClave.confirmacionClave
                  = !mostrarCamposClave.confirmacionClave"
              />
            </mdb-input>
            <p
              v-if="validarValores.confirmacionClave == 'invalido'"
              class="mensaje-invalido"
            >
              Confirma la clave
            </p>
          </div>
        </div>
        <div v-else>
          <p class="mb-n1 px-2 font-italic">
            Se ha enviado un código a tu correo, revísalo para recuperar la clave
          </p>
          <div
            :class="['px-3',
              {'campo': validarValores.codigo && validarValores.codigo.length},
              {'valido': validarValores.codigo === 'valido'},
              {'invalido': validarValores.codigo === 'invalido'}]"
          >
            <mdb-input
              v-model.trim="codigo"
              label="Ingresa el código"
              outline
            />
          </div>
        </div>
        <mdb-btn
          type="submit"
          role="button"
          color="primario"
          :icon="botonDeshabilitado ? 'circle-notch' : 'check'"
          :icon-class="botonDeshabilitado ? 'fa-spin' : ''"
          :disabled="botonDeshabilitado"
          class="col-auto px-3"
        >
          {{ esRecuperarClave ? 'Guardar' : 'Confirmar' }}
        </mdb-btn>
      </form>
    </div>
    <AlertaMensaje
      :alerta-mensaje="alertaMensaje"
      @cerrar="alertaMensaje.contenido = ''"
    />
  </section>
</template>

<script>
import {
  mdbBtn,
  mdbInput
} from 'mdbvue'
import AlertaMensaje from '@/components/AlertaMensaje.vue'
import usuarioCambiarClaveGql from '@/graphql/usuarioCambiarClave.gql'
import verificarCodigoRecuperarClaveGql from '@/graphql/verificarCodigoRecuperarClave.gql'
import { onLogin } from '@/vue-apollo.js'
import { leerPersonaId } from '@/utils/datosToken.js'
export default {
  name: 'RecuperarClave',
  components: {
    mdbBtn,
    mdbInput,
    AlertaMensaje
  },
  data () {
    return {
      // Verificar si se encuentra en el paso de recuperar clave
      esRecuperarClave: false,
      // Otros
      alertaMensaje: { contenido: '' },
      botonDeshabilitado: false,
      codigo: '',
      validarValores: {},
      clave: '',
      confirmacionClave: '',
      mostrarCamposClave: {
        clave: false,
        confirmacionClave: false
      }
    }
  },
  methods: {
    submitForm () {
      this.validarValores = {
        codigo: this.codigo !== '' ? 'valido' : 'invalido'
      }
      if (this.codigo === '') {
        this.alertaMensaje = {
          contenido: 'Debes ingresar el código enviado al correo',
          tipo: 'error'
        }
        return
      }
      this.botonDeshabilitado = true
      this.$apollo
        .mutate({
          mutation: verificarCodigoRecuperarClaveGql,
          variables: {
            recuperacionId: this.codigo,
            usuario: this.$route.params.usuario
          }
        })
        .then(({ data: { verificarCodigoRecuperarClave } }) => {
          const { codigo, token } = verificarCodigoRecuperarClave
          switch (codigo) {
            case 'Correcto':
              this.alertaMensaje = {
                contenido: 'Tu cuenta ha sido verificada correctamente, ingresa tu nueva clave',
                tipo: 'correcto'
              }
              onLogin(this.$apolloProvider.clients.defaultClient, token)
              this.esRecuperarClave = true
              break
            case 'Fallido':
              this.validarValores.codigo = 'invalido'
              this.alertaMensaje = {
                contenido: 'El código que ingresaste no es válido',
                tipo: 'error'
              }
              break
            case 'UsuarioInactivo':
              this.validarValores.codigo = 'invalido'
              this.alertaMensaje = {
                contenido: 'El código de verificación que ingresaste ha expirado, hemos enviado uno nuevo a tu correo',
                tipo: 'advertencia'
              }
              break
            default:
              this.validarValores.codigo = 'invalido'
              this.alertaMensaje = {
                contenido: 'Ha ocurrido un error inesperado',
                tipo: 'error'
              }
              break
          }
          this.botonDeshabilitado = false
          this.codigo = ''
        })
        .catch((e) => {
          this.alertaMensaje = {
            contenido: `Error: ${e}`,
            tipo: 'error'
          }
          this.codigo = ''
          this.validarValores = {}
          this.botonDeshabilitado = false
        })
    },
    validarClave () {
      this.validarValores = {
        clave: this.clave.trim() ? 'valido' : 'invalido',
        confirmacionClave: this.confirmacionClave.trim() ? 'valido' : 'invalido'
      }
      // Verifica si existe algún dato invalido
      if (Object.values(this.validarValores).includes('invalido')) {
        this.alertaMensaje = {
          contenido: 'Todos los campos son requeridos',
          tipo: 'error'
        }
        this.botonDeshabilitado = false
        return
      }
      if (this.clave.length < 8) {
        return (this.alertaMensaje = {
          contenido: 'La clave debe contener al menos 8 caracteres',
          tipo: 'advertencia'
        })
      }
      if (this.clave !== this.confirmacionClave) {
        this.validarValores.confirmacionClave = 'invalido'
        return (this.alertaMensaje = {
          contenido: 'La confirmación de la clave no coincide. Por favor verifica tus datos',
          tipo: 'advertencia'
        })
      }
      this.usuarioCambiarClave()
    },
    usuarioCambiarClave () {
      this.botonDeshabilitado = true
      this.$apollo
        .mutate({
          mutation: usuarioCambiarClaveGql,
          variables: {
            personaId: leerPersonaId(),
            clave: this.clave
          }
        })
        .then(({ data: { usuarioCambiarClave } }) => {
          const msg = usuarioCambiarClave.codigo
          switch (msg) {
            case 'Correcto':
              this.alertaMensaje = {
                contenido: 'Se ha modificado la contraseña con éxito.',
                tipo: 'correcto'
              }
              this.limpiarCampos()
              this.botonDeshabilitado = false
              this.$router.push('/')
              break
            case 'Fallido':
              this.alertaMensaje = {
                contenido: 'Ha ocurrido un error modificando la contraseña.' +
                    ' Por favor intenta de nuevo',
                tipo: 'error'
              }
              this.botonDeshabilitado = false
              break
            default:
              this.alertaMensaje = {
                contenido: 'Ha ocurrido un error inesperado. Por favor intenta de nuevo',
                tipo: 'error'
              }
              this.botonDeshabilitado = false
              break
          }
        })
        .catch(() => {
          this.alertaMensaje = {
            contenido: 'Ha ocurrido un error inesperado. Por favor revisa tus datos e intenta nuevamente',
            tipo: 'error'
          }
          this.botonDeshabilitado = false
        })
    },
    limpiarCampos () {
      this.clave = ''
      this.confirmacionClave = ''
      this.validarValores = {}
      this.mostrarCamposClave = {
        clave: false,
        confirmacionClave: false
      }
    }

  }
}
</script>

<style lang="scss" scoped>

.vista-formulario {
  align-items: center;
  background: $terciario;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' width='100%' height='auto' preserveAspectRatio='none' viewBox='0 0 1920 1080'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1030%26quot%3b)' fill='none'%3e%3crect width='1920' height='1080' x='0' y='0' fill='rgba(7%2c 26%2c 106%2c 1)'%3e%3c/rect%3e%3cpath d='M0%2c761.365C140.853%2c762.126%2c255.006%2c657.106%2c370.248%2c576.116C481.009%2c498.275%2c619.848%2c430.162%2c656.65%2c299.882C692.996%2c171.214%2c560.517%2c53.734%2c555.554%2c-79.877C549.653%2c-238.743%2c705.267%2c-404.449%2c625.382%2c-541.897C548.813%2c-673.639%2c349.423%2c-649.389%2c202.048%2c-688.112C59.348%2c-725.607%2c-83.368%2c-807.62%2c-224.701%2c-765.261C-365.865%2c-722.953%2c-447.291%2c-582.333%2c-538.113%2c-466.278C-624.657%2c-355.69%2c-738.825%2c-246.855%2c-740.282%2c-106.437C-741.713%2c31.487%2c-611.923%2c128.235%2c-545.535%2c249.138C-485.156%2c359.099%2c-459.137%2c486.26%2c-367.685%2c572.129C-265.12%2c668.432%2c-140.689%2c760.605%2c0%2c761.365' fill='%23051145'%3e%3c/path%3e%3cpath d='M1920 1949.984C2080.718 1948.69 2200.111 1812.775 2339.479 1732.722 2487.433 1647.737 2681.551 1614.847 2763.758 1465.3310000000001 2846.841 1314.222 2808.862 1125.3 2765.173 958.483 2724.746 804.124 2624.034 680.844 2525.443 555.381 2421.929 423.653 2340.114 241.63 2175.876 208.567 2011.326 175.44100000000003 1870.581 322.40200000000004 1718.244 392.88199999999995 1590.345 452.05600000000004 1461.114 498.155 1353.442 589.075 1230.2 693.143 1064.033 794.366 1052.2730000000001 955.24 1040.446 1117.038 1216.842 1223.444 1296.75 1364.629 1367.908 1490.354 1390.855 1640.6100000000001 1496.158 1739.511 1613.5149999999999 1849.733 1759.003 1951.28 1920 1949.984' fill='%230a238f'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1030'%3e%3crect width='1920' height='1080' fill='white'%3e%3c/rect%3e%3c/mask%3e%3c/defs%3e%3c/svg%3e");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  height: 100vh;

  .tarjeta-form { // Estilos Formulario Login
    background-color: $claro;
    border-radius: .125rem .125rem 7px 0;
    box-shadow: 0 2px 5px 0 rgba(14, 7, 24,0.16), 0 2px 10px 0 rgba(14, 7, 24,0.12);
    height: 418px;
    margin-top: -2rem;
    max-width: 292px;
    padding: 1rem 1.25rem 1.5rem;
    position: relative;
    text-align: center;
    transition: height .5s ease;
    width: 90%;

    @media screen and (min-width: 295px) {height: 375px;}

    &::before {
      background: $claro;
      border-radius: 0 0 7px 50px;
      box-shadow: -4px 7px 8px 0 rgba(14, 7, 24, 0.12);
      bottom: -24px;
      content: "";
      height: 50px;
      left: 2px;
      position: absolute;
      transform: rotate(-9deg);
      transition-property: transform, border-radius, box-shadow;
      transition-duration: .5s;
      transition-timing-function: ease;
      width: 99%;
      z-index: 0;
    }

    &.registro {height: 500px;}
    &.registro::before {
      border-radius: 0 0 50px 7px;
      box-shadow: 4px 7px 8px 0 rgba(14, 7, 24, 0.12);
      left: 1px;
      transform: rotate(7deg);
    }
    &.recuperar-clave {height: 314px;}
  }
}

</style>
